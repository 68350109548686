import React, { useEffect, useState } from "react";
import '../../assets/css/loader.css';

const Refer = () => {
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const reloadKey = "referPageReloaded";

    if (!sessionStorage.getItem(reloadKey)) {
      sessionStorage.setItem(reloadKey, "true");
      window.location.reload();
    }

    return () => {
      sessionStorage.removeItem(reloadKey);
    };
  }, []);

  useEffect(() => {
    const checkWidgetLoaded = () => {
      const widget = document.getElementById("referralhero-dashboard-MFcfb6135691");
      if (widget && widget.children.length > 0) {
        setLoading(false); 
      }
    };

    const interval = setInterval(checkWidgetLoaded, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div style={{ height: "100%" }}>
        <div style={{ backgroundColor: "#f8f9fa", position: "relative", height: "100%" }}>
          <div
            style={{
              height: "50vh",
              width: "100%",  
              background: "linear-gradient(to right,#11cdef 0%,  #0056b3 50%)", 
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <h1 style={{ fontSize: "40px", color: "white", fontWeight: "bold", marginTop: "-100px" }}>
              Refer Your Friends
            </h1>
            <br />
            <p style={{ fontSize: "20px", color: "white", marginTop: "-30px" }}>
              Earn $50 for every friend you refer.
            </p>
          </div>

          {loading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}

          <div style={{ paddingTop: "10px", marginTop: "-150px", marginBottom: "130px" }} id="referralhero-dashboard-MFcfb6135691"></div>
        </div>
      </div>
    </>
  );
};

export default Refer;
