import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

const App = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.textContent = `
      !function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid
      =t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function()
      {r.queue.push(arguments)};e=a.getElementsByTagName('script')
      [0],c=a.createElement('script');c.async=!0,c.src=
      'https://referralhero-global-code.s3.amazonaws.com/'+'production'+
      '/'+t+'.js',e.parentNode.insertBefore(c,e)}(window,document,
      'https://app.referralhero.com/','RH100a9ef3ea');
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
